.stats-wrapper {
    padding-top: 50px;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offers-overview-row {
    display: flex;
    gap: 50px;
}

.offers-overview-card {
    display: flex;
    flex-direction: column;
    width: 475px;
    height: 140px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 40px;
}

.offers-overview-card2 {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 140px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 40px;
}

.offers-overview-card3 {
    display: flex;
    flex-direction: column;
    width: 930px;
    height: 140px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 40px;
}

.offers-overview-card-text {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #36353D;
    padding-bottom: 20px;
}

.offers-overview-card-number {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
}

.offers-overview-row-card-container {
    display: flex;
    width: 1000px;
    height: 140px;
    padding: 25px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 40px;
}

.offers-overview-row-card:nth-child(1) {
    padding: 0px 60px 0px 0px;
}

.offers-overview-row-card:nth-child(3) {
    padding: 0px 49px 0px 25px;
}

.offers-overview-row-card:nth-child(4) {
    border-right: none;
}

.offers-overview-row-card {
    border-right: 1px solid #2A7AF5;
    padding: 0px 60px 0px 25px;
}

.offers-overview-hr {
    width: 25px;
    height: 6px;
}

/* LINE CHART */
.linechart-container {
    width: 1000px;
    height: 448px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 40px;
}

.chart-title {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 20px;
    color: #36353D;
}

/*   PROGRESS BAR */

.progress-bar-container {
    display: flex;
    gap: 50px;
}

.progress-bar-card {
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 280px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 25px;
}

.circular-progress-bar {
    width: 170px;
    height: 170px;
    align-self: center;
    margin-top: 15px;
}

.audience-information-row {
    display: flex;
    gap: 50px;
}

.audience-information-pie-card {
    position: relative;
    width: 440px;
    height: 360px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 40px;
}

.hide-chart-labels .recharts-layer text,
.hide-chart-labels .recharts-pie-labels,
.hide-rechart-curve .recharts-curve {
    display: none;
}

.recharts-legend-wrapper {
    visibility: hidden;
}

.custom-legend {
    position: absolute;
    right: 25px;
    top: 30%;
    display: flex;
    flex-direction: column;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.legend-age-range {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.legend-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.legend-icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.legend-number {
    font-family: 'Figtree';
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.legend-bar {
    width: 100%;
    height: 14px;
    background-color: #D9D9D9;
    border-radius: 5px;
    margin-bottom: 30px;
    z-index: 0;
}

.legend-bar-pourcent {
    z-index: 1;
    background-color: #2A7AF5;
    height: 14px;
    border-radius: 5px;
}

.legend-label {
    font-family: 'Figtree';
    font-size: 16px;
    color: black;
}

.audience-information-location-row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;
}

.audience-information-location-legend {
    width: 213px;
}

.audience-information-location-card {
    width: 930px;
    height: 600px;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}



#map {
    width: 480px;
    height: 470px;
}