.createOffer-header {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: black;
    height: 45px;
    padding-left: 45px;
    padding-right: 45px;
    position: fixed;
    top: 0;
    z-index: 99;
}

.createOffer-newOffer {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    background: white;
}

.createOffer-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    background: #FEF7EA;
}

.createOffer-mainTitle {
    font-size: 34px;
    font-family: Figtree;
    font-weight: bold;
    padding-bottom: 10px
}

.createOffer-mainSubtitle {
    display: none;
    font-size: 16px;
    font-family: Figtree;
    padding-bottom: 50px
}
.createOffer-preview-iphone-scrollable {
    overflow-y: scroll;
    width: 91.75%;
    height: 91.1%;
    left: 3.5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: absolute;
    top: 45px;
    white-space: pre-wrap;
    /* CSS3 */
    white-space: -moz-pre-wrap;
    /* Firefox */
    white-space: -pre-wrap;
    /* Opera <7 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* IE */
    border-bottom-right-radius: 14%;
    border-bottom-left-radius: 14%;
}
.createOffer-preview-iphone-container {
    display: none;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.createOffer-content-wrapper {
    display: flex;
}

.createOffer-modify-container {
    width: 100%;
}

.createOffer-modify-form-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
}

.createOffer-offerType-container {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    margin-top: 10px;
}

.createOffer-offerType-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.createOffer-information-icon {
    font-size: 25px;
    color: #2A7AF5;
    margin-left: 12px;
    cursor: pointer;
}

.createOffer-offerType-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 25px;
    height: 40px;
    background: #E8E8E8;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: Figtree;
    font-size: 13px;
    border: none;
    cursor: pointer;
}

.createOffer-modal-bubble-informations {
    width: 275px;
    height: 275px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    position:absolute;
    padding:20px;
}

.createOffer-modify-form-input-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.createOffer-modify-form-photos-row {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 15px;
}

.createOffer-modify-form-photo-file-icon {
    font-size: 45px;
    margin: 0 auto;
    cursor: pointer;
    color: #2A7AF5;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    z-index: 99;
}

.error-msg {
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
    font-weight: bold;
    color: red;
    text-align: left;
}

/* DESKTOP */
@media (min-width: 768px) {
    .createOffer-header {
        display: flex;
    }

    .createOffer-newOffer {
        padding-left: 50px;
        padding-right: 50px;
    }

    .createOffer-mainSubtitle {
        display: block
    }

    .createOffer-wrapper {
        display: flex;
        padding-left: 50px;
        padding-right: 50px;
    }

    .createOffer-content-wrapper {
        flex-direction: column;
    }

    .createOffer-preview-iphone-container {
        display: flex;
        flex-direction: column;
    }

    .createOffer-modify-container {
        width: 100%;
    }

    .createOffer-modify-form-container {
        margin-right: 0px;
    }

    .createOffer-modify-form-input-wrap {
        width: 100%;
    }

    .createOffer-modify-form-photos-row {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        margin-top: 10px;
        height: auto;
    }

    .createOffer-modify-form-photo-file-icon {
        color: white;
    }

    .createOffer-preview-iphone-container {
        display: none;
    }
}

@media (min-width: 1200px) {

    .createOffer-modify-container {
        width: 70%;
    }

    .createOffer-content-wrapper {
        flex-direction: row;
        justify-content: space-between;
    }

    .createOffer-preview-iphone-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 105px;
    }

    .createOffer-modify-form-container {
        margin-right: 50px;
    }

    .createOffer-modify-form-input-wrap {
        width: 80%;
    }

    .createOffer-preview-iphone-container {
        align-items: flex-start;
    }

    .createOffer-modify-form-photos-row {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (min-width: 1400px) {
    .createOffer-modify-form-photos-row {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}