.selection-of-the-week-header {
    position: relative;
    margin-left: 20px;
    padding-top: 80px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selection-of-the-week-random-selection {
    width: 140px;
    height: 40px;
    position: absolute;
    left: 0px;
    font-family: "Geomatrix";
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    transition: .3s ease;
    cursor: pointer;
}

.selection-of-the-week-random-selection:hover {
    left: 10px;
    background-color: rgb(43, 139, 250);
    border: 1px solid black;
    color: white;
    font-weight: 700;
    transform: scale(1.1);
}

.selection-of-the-week-title {
    text-transform: "uppercase";
    text-align: "center";
    font-size: 25px;
    font-weight: bold;
    font-family: "Geomatrix";
}

.selection-of-the-week-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.selection-of-the-week-left-col {
    width: 70%;
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    border-radius: 10px;
    margin-left: 20px;
    padding-top: 20px;
    border: 1px solid #2A7AF5;
}

.selection-of-the-week-dots {
    height: 100vh;
    padding-top: 50px;
    display: flex;
    justify-content: center;
}

.selection-of-the-week-partners-wrap {
    margin: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
}

.selection-of-the-week-partners-wrap p {
    font-family: "Figtree"
}

.selection-of-the-week-partners-logo {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.selection-of-the-week-add-partners {
    margin-top: 5px;
    background-color: red;
    border: none;
    border-radius: 5px;
    padding: 5px;
    color: white;
    font-family: 'Geomatrix';
    cursor: pointer;
}

.selection-of-the-week-remove-partners {
    margin-top: 5px;
    background-color: rgb(42, 122, 245);
    border: none;
    border-radius: 5px;
    padding: 5px;
    color: white;
    font-family: 'Geomatrix';
    cursor: pointer;
}

.selection-of-the-week-content h2 {
    padding-left: 30px;
    padding-top: 30px;
    font-family: 'Geomatrix';
    font-size: 22px;
}

.selection-of-the-week-list-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 30px;
    background-color: rgb(0, 28, 53);
    border-radius: 5px;
}

.selection-of-the-week-list-partners {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selection-of-the-week-list-partners p {
    color: white;
    margin-top: 10px;
    font-family: 'Geomatrix';
}

.selection-of-the-week-selection-row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selection-of-the-week-submit {
    border: none;
    background-color: #2A7AF5;
    font-weight: bold;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    margin-top: 30;
    color: white;
}

.selection-of-the-week-previous-selection {
    width: 25%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #7FAFF9, #2A7AF5);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-right: 20px;
    padding-top: 20px;
    border-width: 1px;
    border-color: #2A7AF5;
}

.selection-of-the-week-previous-selection-content {
    margin: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.selection-of-the-week-previous-selection-content img {
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

.selection-of-the-week-previous-selection-name {
    padding-left: 15px;
    font-family: "Figtree";
    font-size: 22px;
}

.selection-of-the-week-previous-selection-slogan {
    padding-top: 10px;
    padding-left: 15px;
    font-family: "Figtree";
    font-size: 16px;
    opacity: 0.75;
}

.promotions-wrapper {
    display: flex;
}

.promotion {
    width: 45%;
    padding: 0px 30px 0px 30px;
}

.promotion h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Geomatrix";
}

.promotion-products-form {
    background-color: #FEF7EA;
    width: 100%;
    padding: 40px;
}

.promotion-products-form input::placeholder {
    color: #03030350;
    opacity: 1;
    font-size: 18px;
    font-family: "Figtree";
}

.promotion-category-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 300px;
}

.promotion-category-text-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.promotion-remove-category-button {
    margin-left: 10px;
    padding: 6px 12px;
    background-color: #ff5a5ac0;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.promotion-remove-category-button:hover,
.promotion-existing-category button:hover {
    background-color: #ff0000;
    transition: .3s ease-in;
}

.promotion-add-category-button,
.promotion-submit-button {
    padding: 8px 16px;
    background-color: #1b1b29b0;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.promotion-add-category-button:hover,
.promotion-submit-button:hover {
    background-color: #010141;
    transition: .3s ease-in;
}

.promotion-existing-category {
    border: 1px solid #1B1B29;
    padding: 8px 16px;
    background-color: #FFF7EF;
    display: flex;
    border-radius: 5px;
    flex-wrap: wrap;
}

.promotion-existing-category p {
    font-size: 16px;
    font-family: "Figtree";
    color: #1B1B29;
    padding-bottom: 5px;
    text-align: center;
}

.promotion-existing-category button {
    margin-right: 10px;
    padding: 6px 12px;
    background-color: #ff5a5ac0;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.promotion-create-categories p {
    color: #023875;
    text-decoration: underline;
    padding-left: 5px;
    cursor: pointer;
}

.promotion h3 {
    font-size: 16px;
    font-family: "Figtree";
    color: #1B1B29;
    padding-bottom: 5px;
}

.promotion-redirect-assignPartners {
    margin-top: 30px;
    margin-right: 10px;
    padding: 6px 12px;
    background-color: #1B35FE;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Figtree";
    transition: .5s ease-in-out;
}

.promotion-redirect-assignPartners:hover {
    transform: translate(10px, 0%);
}

.promotions-order {
    padding: 30px;
}

.promotions-order h2 {
    padding-top: 30px;
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Geomatrix";
}

.promotions-order-dragdrop {
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF7EF;
    border: 0.5px solid #1b1b29;
    border-radius: 5px;
}

.promotions-order .category-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.promotions-order .category-box {
    background-color: #1b1b29;
    color: white;
    padding: 10px;
    border-radius: 4px;
    cursor: grab;
}

.promotions-order .category-box:hover {
    background-color: #333;
}

.promotions-order-dragdrop-update-btn {
    border: none;
    width: 250px;
    background-color: #20A983;
    cursor: pointer;
    color: #FFF;
    font-family: "Figtree";
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
    transition: .3s ease-out;
}

.promotions-order-dragdrop-update-btn:hover {
    transform: translateY(-5px)
}