.promotion-card {
    background-color: white;
    padding-left: 40px;
    padding-top: 25px;
    padding-bottom: 50px;
    position: relative;
}

.promotion-card-header {
    display: flex;
}

.promotion-card-header h3 {
    color: #000;
    font-family: 'Figtree';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.promotion-card-content {
    margin: 0 auto;
    width: 85%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px dashed #F5761D;
    padding: 30px 115px;
}

.promotion-card-expirationDate {
    color: #000;
    font-family: "Figtree";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 20px;
    right: 80px;
}

.promotion-card-check-icon {
    position: absolute;
    top: 16px;
    right: 30px;
    font-size: 30px;
}

.promotion-card-content-header {
    color: #F5761D;
    text-align: center;
    font-family: 'Figtree';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 20px;
}

.promotion-card-content-title {
    color: #000;
    text-align: center;
    font-family: 'Figtree';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 20px;
}

.promotion-card-content-description {
    color: #676767;
    text-align: center;
    font-family: 'Figtree';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}