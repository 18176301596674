.preview-wrapper {
    min-height: 100vh;
    width: calc(100vw - 250px);
    padding-bottom: 100px;
    background: #FEF7EA;
}

.preview-header-container {
    padding-top: 50px;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.preview-header-title {
    font-family: "Figtree";
    max-width: 100%;
    font-size: 1.8vw;
    font-weight: bold;
    padding-top: 50px;
    padding-bottom: 50px;
}

.preview-iphone-container {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.preview-iphone-containerbis {
    display: flex;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.preview-iphone {
    position: relative;
    width: 365px;
    height: 688px;
    background-image: url(../../assets/img/iphone2.png);
    background-repeat: no-repeat;
}

.preview-iphone-scrollable-container {
    position: relative;
    width: 365px;
    height: 688px;
    border-radius: 15pt;
    overflow: hidden;
}

.preview-iphone-clock {
    position: absolute;
    top: 25px;
    left: 45px;
    font-weight: 700;
    font-size: 13px;
    font-family: "Roboto";
}

.preview-iphone-offer-status {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 185px;
    height: 32px;
    right: 60px;
    top: 65px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    z-index: 1;
}

.preview-iphone-offer-status-expired {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 32px;
    right: 60px;
    top: 65px;
    background: linear-gradient(97.25deg, #f1785a 1.21%, #ff0000 93.66%);
    border-radius: 15px;
    z-index: 1;
}

.preview-iphone-offer-status-text {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
}

.preview-iphone-scrollable {
    overflow-y: scroll;
    width: 91.75%;
    height: 85.5%;
    left: 3.5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: absolute;
    top: 85px;
    white-space: pre-wrap;
    /* CSS3 */
    white-space: -moz-pre-wrap;
    /* Firefox */
    white-space: -pre-wrap;
    /* Opera <7 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* IE */
    border-bottom-right-radius: 14%;
    border-bottom-left-radius: 14%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.preview-iphone-scrollable::-webkit-scrollbar {
    display: none;
}

.preview-iphone-scrollable-elements {
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: pre-wrap;
    /* CSS3 */
    white-space: -moz-pre-wrap;
    /* Firefox */
    white-space: -pre-wrap;
    /* Opera <7 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* IE */
}

.preview-iphone-scrollable-element-nopic {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 370px;
}

.preview-iphone-scrollable-element-nopic2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 327px;
}

.preview-iphone-scrollable-element-img {
    width: 90%;
    min-height: 457px;
    max-height: 457px;
    object-fit: cover;
}

.preview-iphone-scrollable-element-profil-img {
    width: 90%;
    min-height: 220px;
    max-height: 220px;
    object-fit: cover;
}

.preview-iphone-scrollable-element-img2 {
    width: 90%;
    min-height: 327px;
    max-height: 327px;
    object-fit: cover;
}

.preview-iphone-scrollable-element-logo {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    margin-left: 15px;
}

.preview-iphone-scrollable-element-partner {
    color: black;
    font-family: "Figtree";
    font-size: 20px;
    margin-left: 7px;
    font-weight: bold;
    text-transform: uppercase;
}

.preview-iphone-scrollable-profil-element-partner {
    color: black;
    font-family: "Figtree";
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    width: 210px;
    padding-bottom: 5px;
}

.preview-iphone-scrollable-profil-element-partner-slogan {
    width: 210px;
    color: #9B9B9B;
    font-family: 'Figtree';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.preview-iphone-scrollable-profil-labels-container {
    padding-left: 10px;
    padding-right: 10px;
    width: 300px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}

.preview-iphone-scrollable-element-title {
    color: black;
    font-family: "Figtree";
    font-size: 20px;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    width: 210px;
}

.preview-iphone-scrollable-element-name {
    color: black;
    font-family: "Figtree";
    font-size: 20px;
    padding-left: 16px;
    padding-right: 16px;
    width: 307px;
}

.preview-iphone-scrollable-element-slogan {
    color: #6A6969;
    font-family: "Figtree";
    font-size: 17px;
    padding-top: 3px;
    padding-left: 16px;
    padding-right: 16px;
    width: 307px;
    text-align: center;
    padding-bottom: 25px;
}

.preview-iphone-scrollable-element-profil-description {
    position: relative;
    color: black;
    font-family: "Figtree";
    font-size: 16px;
    width: 302px;
    
    border-top: 0.5px solid #B7B7BD;
}

.preview-iphone-scrollable-element-profil-description .row-title-text {
    padding-left: 10px;
    font-family: "Figtree";
    font-weight: 600;
    font-style: normal;
}

.preview-iphone-scrollable-element-profil-description .row-content-text {
    font-family: 'Figtree';
    font-weight: 400;
}

.preview-iphone-scrollable-element-description {
    color: black;
    font-family: "Figtree";
    font-size: 16px;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
    width: 307px;
}

.preview-iphone-scrollable-element-noDescription {
    width: 275px;
    background: #DADADA;
    border-radius: 5px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.preview-iphone-scrollable-element-noDescription>p {
    color: black;
    font-family: "Figtree";
    font-size: 16px;
    padding-top: 5px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 27px;
}

.preview-iphone-profil-box-container {
    display: flex;
    z-index: 1;
    width: 300px;
    padding-top: 20px;
    padding-left: 10px;
    align-items: center;
    padding-bottom: 20px;
}

.preview-iphone-profil-box-logo {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    border: 2px solid #000;
    margin-right: 20px;
}

.preview-iphone-profil-column {
    width: 210px;
    display: flex;
    flex-direction: column;
}

.preview-iphone-arraydown-btn-container {
    width: 33.21px;
    height: 33.21px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #4D34E8 0%, #2A7AF5 100%);
    border-radius: 50px;
    z-index: 3;
    position: absolute;
    right: 30px;
    bottom: 0px;
}

.preview-iphone-icon-arraydown {
    color: white;
}

.preview-iphone-actions-wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 40px;
    justify-content: center;
    z-index: 5;
}

.preview-iphone-top-bar {
    position: absolute;
    top: 55px;
    margin-left: 21px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.preview-iphone-back-btn {
    display: flex;
    position: absolute;
    width: 25px;
    height: 25px;
    top: 60px;
    left: 25px;
    align-items: center;
    justify-content: center;
    z-index: 5;
    background: rgba(138, 133, 153, 0.5);
    border-radius: 25px;
}

.preview-iphone-partner-name {
    max-width: 200px;
    max-height: 25px;
    overflow: hidden;
    color: #000;
    text-align: center;
    font-family: 'Figtree';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.preview-iphone-actions-wrapper-profil {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 50px;
    justify-content: center;
    z-index: 5;
}

.preview-iphone-actions-btn-goshop {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 30px 13px 30px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 30px;
    background: #20A983;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.40);
}

.preview-iphone-actions-btn-goshop:hover {
    cursor: pointer
}

.preview-iphone-actions-btn-goshop-text {
    font-family: 'Figtree';
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color:#FFF;
}

.preview-iphone-actions-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    box-shadow: 0px 6.64292px 13.2858px rgba(0, 0, 0, 0.25);
    background: white;
    border-radius: 50px;
    margin-left: 15px;
    margin-right: 15px;
}

.preview-iphone-actions-btn {
    font-size: 30px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-40 {
    margin-top: 40px;
}

.pt-20 {
    padding-top: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-50 {
    padding-top: 50px;
}

.pb-120 {
    padding-bottom: 120px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 91.75%;
}