.modify-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FEF7EA;
}

.modify-sub-wrapper {
    display: flex;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
}

.modify-container {
    width: 100%;
}

.modify-form-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
}

.modify-delete-offer {
    width: 195px;
    height: 50px;
    border: none;
    border-radius: 5px;
    font-family: 'Figtree';
    font-size: 16px;
    color: white;
    text-align: center;
    cursor: pointer;
    background-color: rgba(255, 0, 0, 0.75);
}

.modify-form-input-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.modify-form-label {
    font-size: 16px;
    font-family: Figtree;
    padding-bottom: 5px;
}

.modify-form-label-photo {
    font-size: 16px;
    font-family: Figtree;
    padding-top: 25px;
}

.modify-form-input {
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.modify-form-input-textarea {
    display: flex;
    height: 100px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 10px;
    margin-bottom: 5px;
    resize: none;
    padding: 15px;
}

.modify-date-input-placeholder {
    color: gray;
    margin-bottom: 30px;
}

.modify-date-input-has-value {
    color: black;
    margin-bottom: 30px;
}

.modify-date-error {
    height: 50px;
    background: #FFFFFF;
    border: 1px solid red;
    border-radius: 10px;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
}

.modify-form-input-textarea:focus {
    border: 1px solid #2A7AF5;
}

.modify-form-input:focus {
    border: 1px solid #2A7AF5;
}

.modify-form-length {
    text-align: right;
    font-size: 18px;
    font-family: Figtree;
    padding-top: 5px;
}

.myProfil-form-photos-container {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    width: 70%;
}

.modify-form-photos-container {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
}

.modify-form-photos-row {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    height: auto;
}

.modify-form-photo {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 125%;
    border-radius: 10px;
}

.photos-1 {
    background: #FFFFFF;
    border-radius: 10px;
}

.photos-2 {
    background: #FFFFFF;
    border-radius: 10px;
}

.photos-3 {
    background: #FFFFFF;
    border-radius: 10px;
}

.photos-4 {
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 10px;
}

.modify-form-removePicture {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
}

.modify-form-photo-title {
    position: absolute;
    bottom: 10px;
    font-size: 14px;
    font-family: Figtree;
    width: 100%;
    color: #AFAFAF;
    text-align: center;
}

.modify-form-photo-file-icon {
    font-size: 45px;
    margin: 0 auto;
    cursor: pointer;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
}

.modify-form-submit-container {
    margin-top: 50px;
    align-self: center;
}

.modify-form-submit {
    width: 275px;
    height: 55px;
    background: #2A7AF5;
    border-radius: 30px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    transition: ease-in-out .3s;
}

.modify-form-submit-disabled {
    width: 275px;
    height: 55px;
    background: rgb(25, 25, 25, 0.5);
    border-radius: 30px;
    border: none;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}

/*  PREVIEW */
.modify-preview-container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 40%;
    max-width: 80%;
}

/* Modal */
.modifyModal-box {
    width: 746px;
    height: 345px;
    background: #FFFFFF;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0px 5.48529px 10.9706px rgba(0, 0, 0, 0.25);
    padding: 40px;
}

.modify-header-title {
    font-family: Figtree;
    max-width: 50%;
    padding-top: 50px;
    font-size: 1.8vw;
    font-weight: bold;
}

.modify-deleteOffer-title {
    font-family: 'Figtree';
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 30px;
}

.modify-deleteOffer-text {
    font-family: 'Figtree';
    font-size: 18px;
}

.modify-deleteOffer-btnContainer {
    position: absolute;
    bottom: 40px;
    right: 40px;
}

.modify-deleteOffer-btn-cancel {
    width: 150px;
    height: 42px;
    border: 1px solid #2A7AF5;
    border-radius: 30px;
    font-family: 'Figtree';
    font-size: 18px;
    color: #2A7AF5;
    background: transparent;
    cursor: pointer;
}

.modify-deleteOffer-btn-delete {
    width: 150px;
    height: 42px;
    border: none;
    border-radius: 30px;
    font-family: 'Figtree';
    font-size: 18px;
    color: #FFF;
    /*background: #CACACA;*/
    background: rgba(255, 0, 0, 0.75);
    margin-left: 30px;
    cursor: pointer;
}

@media (min-width: 768px) {
    .modify-sub-wrapper {
        flex-direction: row;
        justify-content: space-between
    }

    .modify-container {
        max-width: 100%;
    }

    .modify-preview-container {
        display: none;
    }

    .modify-form-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    }

    .photos-1 {
        background: #F2DED0;
    }

    .photos-2 {
        background: #F2EAB8;
    }

    .photos-3 {
        background: #BEB8F2;
    }

    .photos-4 {
        background: #A9F2A0;
        margin-top: 0;
    }

    .modify-photos-1 {
        background: #F2EAB8;
    }

    .modify-photos-2 {
        background: #BEB8F2;
    }

    .modify-photos-3 {
        background: #A9F2A0;
    }

    .modify-form-photo-title {
        font-size: calc(4px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
        color: #000000;
    }

    .modify-form-photo-file-icon {
        font-size: calc(25px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
    }

}

@media (min-width: 1100px) {
    .modify-preview-container {
        display: flex;
    }

    .modify-container {
        min-width: 20%;
        max-width: 50%;
    }

    .modify-form-container {
        margin-right: 50px;
    }

    .modify-form-photos-row {
        width: 125%
    }

    .modify-delete-offer {
        margin-right: 100px;
    }
}