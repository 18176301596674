/* Mobile */

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #FAF9FF;
}

.firstColumn {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  width: 100%;
  align-items: center;
}

.secondColumn {
  display: none;
  flex-direction: column;
  width: 50%;
  padding-top: 40px;
  background: linear-gradient(180deg, #110054 0%, #4D34E8 100%);
  align-items: center;
}

.headerTitle {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: bold;
  font-family: 'Geomatrix', sans-serif;
}

.subTitle {
  margin-left: 8px;
  font-size: 15px;
  font-family: 'Geomatrix', sans-serif;
  font-weight: 500;
  border: 1px solid black;
  border-radius: 5px;
  padding: 3px 2px 3px 2px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

.form {
  display: flex;
  flex-direction: column;
}

.formTitle {
  font-size: 22px;
  font-weight: 700;
  font-family: 'Geomatrix', sans-serif;
  margin-bottom: 30px;
  text-align: center;
}

.input-form,
.textarea-form {
  width: 100%;
  height: 55px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding-left: 40px;
  font-weight: 700;
  font-size: 16px;
  color: #ABACAC;
  font-family: 'Figtree', sans-serif;
  border: 1px solid #ABACAC;
}

.input-form:focus,
.textarea-form:focus {
  border: 1px solid #2A7AF5;
}

.links {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.button-form {
  cursor: pointer;
  background-color: #110054;
  color: white;
  text-transform: uppercase;
  border: none;
  padding-left: 0px;
}

.button-form-disabled {
  cursor: auto;
  background-color: rgba(17, 0, 84, 0.5);
  color: white;
  text-transform: uppercase;
  border: none;
  padding-left: 0px;
}

.register {
  padding-left: 20px;
}

.fgt-psw-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.fgt-psw {
  width: 176px;
  font-family: 'Geomatrix', sans-serif;
  font-size: 16px;
  color: #2A7AF5;
  font-weight: 700;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-bottom: 20px;
}

.errorMsg {
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: bold;
  color: red;
  text-align: center;
}

.hr {
  color: #ABACAC;
  margin-bottom: 40px;
}

.toKnowMore {
  font-size: 16px;
  font-family: "Figtree";
  margin: 0 auto;
  font-family: "Figtree";
  border: none;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
}

.toKnowMoreSpan {
  color: #2A7AF5;
  font-family: "Figtree"
}

.flex-row {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  align-items: center;
}

.back-icon-text {
  color: #AC97FF;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}

.icon-title {
  font-weight: bold;
  color: white;
  font-size: 18px;
  font-family: "Figtree";
}

.icon {
  font-size: 30px;
  margin-right: 40px;
}

.loginRightPanelImg {
  max-height: 600px;
  margin-bottom: 20px;
  border-radius: 30px;
  width: 70%;
}

.why-sharlock {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.know-more-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 55px;
  background: #8171E4;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  align-self: center;
  margin-bottom: 100px;
  font-family: "Figtree";
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.secondTitle {
  color: white;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Geomatrix', sans-serif;
  margin-bottom: 30px;
  text-align: center;
}


@media (min-width: 768px) {
  .wrapper {
    flex-direction: row;
  }

  .firstColumn {
    width: 50%;
  }

  .secondColumn {
    display: flex;
  }

  .headerTitle {
    margin-bottom: 200px;
  }

  .formTitle {
    display: none;
  }

  .container {
    width: 90%;
  }

  .title {
    font-size: 25px;
  }

  .secondTitle {
    font-size: 26px;
  }

  .hr {
    display: none;
  }

  .toKnowMore {
    display: none;
  }

}

@media (min-width: 1200px) {
  .container {
    width: 50%;
  }

}