.container-carousel {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 302px;
    height: 325px;
    position: relative;
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-slide {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
}

.carousel-img {
    width: 100%;
    min-height: 302px;
    max-height: 302px;
    object-fit: cover;
}

/* Additional styling for active slide */
.carousel-slide.active {
    opacity: 1;
    visibility: visible;
}

.carousel-dots-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    bottom: 40px;
    align-self:center;
}

.carousel-dots {
    width: 7px;
    height: 7px;
    border-radius: 7px;
    border: 0.5px solid #1B1B29;
    background: rgba(255, 255, 255, 0.80);
    margin: 0 3px;
    transition: all 0.3s ease-in-out;
}

.carousel-dots.active {
    background: #B0B9FF;
}