.fgt-pass {
    background-color: #FEF7EA;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 5vh;
}

.fgt-pass-banner {
    display: flex;
    align-items: center;
    background-color: black;
    height: 65px;
    justify-content: center;
}

.fgt-pass-desktop-banner {
    width: 100%;
    height: 57px;
    background-color: black;
    margin-bottom: 110px;
    display: none;
    justify-content: space-between;
    padding-left: 45px;
    padding-right: 45px;
}

.fgt-pass-desktop-banner-firstrow {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.fgt-pass-title {
    font-size: 25px;
    color: white;
    font-family: "Geomatrix";
    font-weight: 700;
    text-transform: uppercase;
}

.fgt-pass-subTitle {
    margin-left: 8px;
    padding: 5px;
    border: 1px solid white;
    color: white;
    font-size: 15px;
    border-radius: 4px;
}

.fgt-pass-h2 {
    font-family: "Geomatrix";
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: none;
}

.fgt-pass-navigation-back-icon {
    color: white;
    font-size: 25px;
    position: absolute;
    top: 20px;
    left: 15px;
}


.fgt-pass-navigation-text {
    color: white;
    font-family: "Geomatrix";
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.fgt-pass-details {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fgt-pass-details-row {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
}

.fgt-pass-circle-step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #2A7AF5;
    border-radius: 15px;
    margin-right: 15px;
    flex-shrink: 0.1;
}

.fgt-pass-circle-step-text {
    color: white;
    font-size: 16px;
    font-family: Figtree;
    font-weight: bold;
}

.fgt-pass-step-title {
    font-size: 16px;
    font-family: "Figtree";
}

.fgt-pass-form {
    margin-right: 15px;
    margin-left: 15px;
}

.fgt-pass-form-row {
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #ABACAC;
    background: #FFFFFF;
    padding-left: 26px;
    padding-right: 20px;
    margin-bottom: 15px;
}

.fgt-pass-form-row-focus {
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #ABACAC;
    background: #FFFFFF;
    padding-left: 26px;
    padding-right: 20px;
    margin-bottom: 15px;
    border: 2px solid #3300FF;
}

.fgt-pass-input {
    width: 100%;
    border: none;
    font-weight: bold;
    font-size: 16px;
    color: #ABABAB;
    font-family: "Figtree";
}

.fgt-pass-form-cross {
    color: red;
    font-size: 25px;
}

.fgt-pass-form-check {
    color: #0DF500;
    font-size: 30px;
}

.fgt-pass-submit {
    width: 100%;
    height: 55px;
    background: black;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    font-family: "Figtree"
}

.fgt-pass-submit-disabled {
    width: 100%;
    height: 55px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    text-transform: uppercase;
    font-family: "Figtree";
    position: relative;
}

.fgt-pass-submit-container {
    position: relative;
    width: 100%;
    display: flex;
}

.fgt-pass-resend-waiting-time {
    position: absolute;
    right: 25px;
    top: 20px;
    z-index: 2;
    font-family: "Figtree";
    background-color: #2A7AF5;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.fgt-pass-errorMsg {
    padding-top: 20px;
    margin-bottom: -20px;
    color: red;
    text-align: center;
}

/* DESKTOP */
@media (min-width: 768px) {
    .fgt-pass-navigation-back-icon {
        display: none;
    }

    .fgt-pass-banner {
        display: none;
    }

    .fgt-pass-desktop-banner {
        display: flex;
    }

    .fgt-pass-h2 {
        display: block;
    }

    .fgt-pass-details {
        margin-top: 0;
    }

    .fgt-pass-form {
        width: 50%;
        margin: 0 auto;
        margin-top: 80px;
        padding: 40px;
        background-color: white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
        border-radius: 30px;
    }

    .fgt-pass-form-row {
        height: 55px;
    }

    .fgt-pass-form-row-focus {
        height: 55px;
    }

    .fgt-pass-submit {
        height: 55px;
        margin-bottom: 25px;
    }

    .fgt-pass-submit-disabled {
        height: 55px;
        margin-bottom: 25px;
    }
}

@media (min-width: 1024px) {
    .fgt-pass-form {
        width: 33%;
    }
}