.myProfil-container-list {
    background: #FEF7EA;
    display: flex;
    flex-direction: column;
    padding: 50px 135px 50px 135px;
}

.myProfil-modify-btn {
    display: flex;
    width: 247px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background: #2A7AF5;
    border-radius: 30px;
    cursor: pointer;
}

.myProfil-modify-btn-text {
    font-family: 'Figtree';
    font-size: 16px;
    color: #FFFFFF;
    padding-left: 14px;
}

.myProfil-modify-form-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 17px 20px;
    width: 247px;
    height: 55px;
    background: #2A7AF5;
    border-radius: 5px;
    align-self: center;
    margin-top: 60px;
    cursor: pointer;
}

.myProfil-modify-form-btn-disabled {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 17px 20px;
    width: 247px;
    height: 55px;
    background-color: #CACACA;
    border-radius: 5px;
    align-self: center;
    margin-top: 60px;
    cursor: not-allowed;
}

.myProfil-modify-form-btn-text {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    text-transform: uppercase;
}

.myProfil-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.myProfil-content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.myProfil-col-1 {
    /*createOffer-modify-container*/
    width: 70%;
}

.myProfil-form {
    display: flex;
    flex-direction: column;
}

.myProfil-form-first-section {
    /*createOffer-modify-form-input-wrap*/
    display: flex;
    flex-direction: column;
    width: 75%;
}

.myProfil-form-label {
    font-family: 'Figtree';
    font-size: 18px;
    margin-bottom: 10px;
}

.myProfil-form-input-length {
    text-align: right;
    font-size: 18px;
    font-family: Figtree;
    margin-top: -30px;
    margin-bottom: 30px;
}

.myProfil-form-input-error {
    color: red;
    font-weight: bold;
    text-align: left;
    font-size: 18px;
    padding-left: 10px;
    font-family: Figtree;
    margin-top: -30px;
    margin-bottom: 30px;
}

.myProfil-form-title2 {
    font-family: 'Figtree';
    font-size: 18px;
    margin-bottom: 30px;
    text-decoration: underline;
}

.myProfil-form-logo {
    width: 70px;
    height: 70px;
    border-radius: 7px;
    object-fit: cover;
    margin-bottom: 40px;
}

.myProfil-form-cover-picture-row {
    display: flex;
    align-items: center;
}

.myProfil-form-cover-picture {
    width: 255px;
    height: 220px;
    margin-bottom: 40px;
    object-fit: cover;
}

.myProfil-form-cover-picture-dimensions {
    font-family: 'Figtree';
    font-size: 18px;
    padding-left: 30px;
}

.myProfil-form-input,
.myProfil-form-input-editable {
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
    font-size: 18px;
    font-family: "Figtree";
}

.myProfil-form-input-editable-error {
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #ff2525;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
    font-size: 18px;
    font-family: "Figtree";
}

.myProfil-form-textarea,
.myProfil-form-textarea-editable {
    height: 104px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
    resize: none;
    font-size: 18px;
    font-family: "Figtree";
}

.myProfil-form-textarea::placeholder,
.myProfil-form-input::placeholder,
.myProfil-form-textarea-editable::placeholder,
.myProfil-form-input-editable::placeholder {
    color: #000000;
    opacity: 1;
    font-size: 18px;
    font-family: "Figtree";
}

.myProfil-form-textarea-editable:focus,
.myProfil-form-input-editable:focus {
    border: 1px solid #3300FF;
}

.myProfil-labels-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 40px;
}

.myProfil-labels-btn {
    display: inline-flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #000;
    background-color: #FFF;
}

.myProfil-labels-btn.selected {
    background-color: #BFD7FC;
}

.myProfil-labels-text {
    color: #000;
    text-align: center;
    font-family: "Figtree";
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.333px;
}

.myProfil-modify-form-photos {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    height: auto;
}

.myProfil-modify-photo {
    position: relative;
    display: flex;
    align-items: center;
    width: 255px;
    height: 198px;
    object-fit: cover;
}

.myProfil-plus-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    cursor: pointer;
}

.myProfil-logo-div {
    width: 70px;
    height: 70px;
    margin-bottom: 40px;
    position: relative;
}

.myProfil-cover-picture-div {
    width: 255px;
    height: 220px;
    margin-bottom: 40px;
    position: relative;
}