.register-title {
    padding-top: 40px;
    padding-bottom: 50px;
    text-transform: uppercase;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    font-family: 'Geomatrix', sans-serif;
}

.register-subtitle {
    font-size: 22px;
    padding-bottom: 30px;
}

.register-form-input-length {
    text-align: right;
    font-size: 14px;
    font-family: Figtree;
    position: absolute;
    right: 0;
    bottom: 5px
}

.field-form {
    position: relative;
    margin-bottom: 10px;
}

.register-submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2A7AF5;
    border: none;
    width: 175px;
    height: 50px;
    border-radius: 8px;
    margin-top: 50px;
    margin-bottom: 50px;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-family: "Figtree";
    cursor: pointer;
}

.register-categories-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.register-categories-partner {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 20px;
}

.register-categories-partner-row {
    padding-top: 10px;
}

.register-subcategories-text {
    padding-left: 5px;
}

.register-label-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.register-label {
    background-color: white;
    color: black;
    border-radius: 20px;
    border: 1px solid black;
    padding: 5px 10px;
    cursor: pointer;
}

.register-label.selected {
    background-color: #bfd7fc;
}