.analytics-container {
    width: 100%;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    min-height: 100vh;
    background: #F4F2FF;
}

.analytics-title {
    font-family: Figtree;
    font-size: 1.8vw;
    font-weight: bold;
}

.analytics-card {
    margin-top: 50px;
    background: #FFFFFF;
    border-radius: 20px;
    height: 268px;
    padding: 16px 24px;
}

.analytics-card-text {
    font-size: 16px;
    font-weight: 700;
    font-family: Figtree;
}