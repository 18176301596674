.myOffers-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background: white;
    z-index: 99;
}

.myOffers-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.myOffers-logo-title {
    font-family: "Geomatrix";
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 8px;
}

.myOffers-logo-subTitle {
    border: 0.846154px solid black;
    border-radius: 3px;
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-family: "Figtree"
}

.myOffers-nav {
    margin-left: 20px;
}

.myOffers-nav-link {
    color: white;
    font-weight: 400;
    font-size: 18px;
    font-family: "Figtree"
}

.myOffers-container-profil {
    display: flex;
    justify-content: flex-end;
}

.myOffers-profil {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2A7AF5;
    width: 35px;
    height: 35px;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 5px;
}

.myOffers-profil-initial {
    color: white;
    font-weight: bold;
    font-size: 17px;
    font-family: "Figtree"
}

.myOffers-profil-desktopHeader {
    justify-content: center;
    align-items: center;
    display: none;
    cursor: pointer;
}

.myOffers-profil-username {
    font-family: 'Figtree';
    font-weight: 700;
    font-size: 16px;
    color: white;
    text-align: center;
}

.myOffers-profil-arraydown {
    color: white;
    margin-left: 12px;
}

.myOffers-main {
    margin-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 100vh;

}

.myOffers-mainTitle {
    font-size: 30px;
    font-family: "Figtree";
    font-weight: bold;
    padding-bottom: 45px
}

.myOffers-mainSubtitle {
    display: none;
    font-size: 16px;
    font-family: "Figtree";
}

.myOffers-main-col-1 {
    flex-direction: column;
    width: 75%;
    justify-content: center;
}

.myOffers-main-col-2 {
    display: none;
    flex-direction: column;
    width: 25%;
    justify-content: center;
}

.myOffers-listOfItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 125px;
}

.myOffers-allOffers {
    display: none;
    background: #FEF7EA;
    padding-left: 135px;
    padding-right: 135px;
    padding-bottom: 55px;
}

.myOffers-allOffers-title {
    font-size: 18px;
    font-family: "Figtree";
    font-weight: bold;
    text-align: left;
    padding-top: 50px;
    padding-bottom: 55px;
}

.myOffers-allOffers-hr {
    color: #2A7AF5;
}

/* First List Item (Desktop) */
.myOffers-container-desktop-list {
    display: none;
    background: #FEF7EA;
    padding: 55px 135px 55px 135px;
}

.myOffers-content-list {
    display: grid;
    gap: 40px;
    padding: 40px;
    overflow: hidden;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));
}

.myOffers-content-list-stats {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(1, minmax(0px, 1fr));
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));
}

.myOffers-content-wrap {
    width: 213px;
    height: 204px;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.myOffers-content-card {
    width: 392px;
    height: 293px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}

.myOffers-content-card-unavailable {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 455px;
    height: 60px;
    background: #2A7AF5;
    border: 3px solid #FFFFFF;
    transform: rotate(-22.36deg);
    position: absolute;
    left: -30px;
    bottom: 145px;
    z-index: 2;
}

.myOffers-content-card-unavailable-text {
    font-family: 'Tabardo';
    font-style: normal;
    font-weight: 400;
    font-size: 29px;
    display: flex;
    align-items: center;
    letter-spacing: 0.055em;
    color: white;
    text-shadow: 0px 1.77901px 1.77901px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
}

.myOffers-content-bottom-card {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 1;
}

.myOffers-content-bottom-card-text {
    font-family: "Figtree";
    font-size: 18px;
    text-align: center;
}

.myOffers-content-card-lineargradient {
    background: linear-gradient(180deg, #FFFFFF 0%, #CFC6FB 100%);
}

.myOffers-content-card-lineargradient2 {
    background: linear-gradient(180deg, #150065 0%, #4D34E8 100%);
}

.myOffers-content-card-img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    position: absolute;
}

.myOffers-content-wrap-img {
    inset: 0px;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    position: absolute;
    top: 0;
    z-index: 1;
}

.myOffers-content-wrap-date {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30%;
    height: 12%;
    right: 10px;
    top: 5%;
    background: #FFFFFF;
    border-radius: 3px;
    z-index: 2;
}

.myOffers-content-wrap-item-date {
    font-size: 0.7rem;
}

.myOffers-content-wrap-status {
    background: linear-gradient(97.25deg, #7FAFF9 1.21%, #2A7AF5 93.66%);
    border-radius: 3px;
    width: 25%;
    height: 12%;
    position: absolute;
    bottom: 20%;
    left: 4%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myOffers-content-wrap-status-expired {
    background: linear-gradient(97.25deg, #f1785a 1.21%, #ff0000 93.66%);
    border-radius: 3px;
    width: 25%;
    height: 12%;
    position: absolute;
    bottom: 20%;
    left: 4%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myOffers-content-wrap-item-status {
    font-size: 0.7rem;
    color: white;
    font-family: "Figtree";
}

.myOffers-content-wrap-description {
    width: 100%;
    height: 25%;
    background: white;
    z-index: 1;
    position: absolute;
    bottom: 0;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.myOffers-content-wrap-item-description {
    font-size: 0.875rem;
    font-family: "Figtree";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.myOffers-item {
    display: flex;
    width: 100%;
    height: 140px;
    background: #F4F2FF;
    border-radius: 10px;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.myOffers-item-imgContainer {
    width: 40%;
    height: 100%;
    margin-right: 15px;
}

.myOffers-item-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.myOffers-item-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 60%;
}

.myOffers-item-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 25px;
    background: linear-gradient(97.25deg, #8270EE 1.21%, #3300FF 93.66%);
    border-radius: 3px;
    margin-bottom: 10px;
}

.myOffers-item-status-expired {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 25px;
    background: linear-gradient(97.25deg, #f1785a 1.21%, #ff0000 93.66%);
    border-radius: 3px;
    margin-bottom: 10px;
}

.myOffers-item-status-text {
    font-family: "Figtree";
    font-size: 14px;
    color: white;
    text-align: center;
}

.myOffers-item-description-text {
    font-family: "Figtree";
    padding-bottom: 10px;
    font-size: 14px;
}

.myOffers-item-container {
    width: 100%;
    cursor: pointer;
}

.myOffers-container-newOfferBtn {
    position: fixed;
    bottom: 10%;
    right: 0;
    left: 0;
}

.myOffers-newOfferBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 49px;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));
    background: #2A7AF5;
    border-radius: 30px;
    cursor: pointer;
    margin: 0 auto;
    transition: ease-in-out .3s;
}

.myOffers-newOfferBtn-icon {
    font-size: 1.2rem;
    color: white;
    margin-right: 10px;
}

.myOffers-newOfferBtn-text {
    color: white;
    font-family: "Figtree"
}

.myOffers-box {
    background: white;
    width: 80%;
    margin-left: 20%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.myOffers-modalContainer {
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    align-items: center;
}

.myOffers-modal-close {
    color: white;
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.myOffers-modal-profilCircle {
    display: flex;
    width: 140px;
    height: 140px;
    background: #2A7AF5;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.myOffers-modal-profiltext {
    color: white;
    font-weight: 700;
    font-size: 68px;
    font-family: "Figtree"
}

.myOffers-modal-title {
    font-family: 'Figtree';
    font-weight: 700;
    font-size: 24px;
}

.myOffers-modal-logoutBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3300FF;
    border-radius: 40px;
    width: 50%;
    height: 49px;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    position: absolute;
    bottom: 40%;
    align-self: center;
    transition: ease-in-out .3s;
}

.myOffers-modal-logoutText {
    font-family: 'Figtree';
    font-style: normal;
    font-size: 16px;
    font-weight: bold;
    color: white;
    transition: ease-in-out .3s;
}

.myOffers-modal-param {
    display: none;
    padding: 30px;
    border-bottom: 1px solid #FFFFFF;
    cursor: pointer;
}

.myOffers-modal-param-text {
    color: white;
    font-size: 16px;
    font-family: 'Figtree';
}

/* DESKTOP */
@media (min-width: 768px) {
    .myOffers-header {
        width: 100%;
        margin-top: 0;
        background: black;
        height: 45px;
        padding-left: 45px;
        padding-right: 45px;
        position: fixed;
        top: 0;
        z-index: 99;
    }

    .myOffers-logo-title {
        font-size: 25px;
        color: white;
    }

    .myOffers-logo-subTitle {
        color: white;
        border: 1px solid #FFFFFF;
        font-size: 15px;
    }

    .myOffers-profil {
        width: 30px;
        height: 30px;
    }

    .myOffers-profil-desktopHeader {
        display: flex;
    }

    .myOffers-main {
        padding-left: 0;
        padding-right: 0;
        margin-top: 80px;
    }

    .myOffers-mainTitle {
        font-size: 34px;
        padding-bottom: 10px;
    }

    .myOffers-mainSubtitle {
        display: block;
    }

    .myOffers-main-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 50px;
        padding-left: 135px;
        padding-right: 135px;
        align-items: center;
    }

    .myOffers-main-col-1 {
        display: flex;
        width: 60%;
    }

    .myOffers-main-col-2 {
        display: flex;
        width: 30%;
    }

    .myOffers-allOffers {
        display: block;
        padding-bottom: 0;
    }

    .myOffers-listOfItems {
        display: none;
    }

    .myOffers-item {
        display: none;
    }

    .myOffers-container-newOfferBtn {
        display: none;
    }

    .myOffers-newOfferBtn {
        width: 309px;
        height: 40px;
    }

    .myOffers-newOfferBtn-text {
        font-size: 16px;
    }

    .myOffers-box {
        width: 401px;
        height: 309px;
        background: black;
        position: absolute;
        top: 65px;
        right: 15px;
        border-radius: 10px;
    }

    .myOffers-box-admin {
        width: 401px;
        height: 450px;
        background: black;
        position: absolute;
        top: 65px;
        right: 15px;
        border-radius: 10px;
    }

    .myOffers-box-admin .myOffers-modal-param:hover {
        background-color: #2A7AF5;
        transition: all 0.8s ease;
    }

    .myOffers-modalContainer {
        padding-top: 30px;
        padding-bottom: 30px;
        flex-direction: row;
        padding-left: 30px;
        padding-right: 30px;
        border-bottom: 1px solid #FFFFFF;
    }

    .myOffers-modal-profilCircle {
        width: 60px;
        height: 60px;
        margin-bottom: 0;
    }

    .myOffers-modal-profiltext {
        font-size: 30px;
    }

    .myOffers-modal-title {
        color: white;
        font-size: 22px;
        margin-left: 25px;
    }

    .myOffers-modal-logoutBtn {
        background: #FFFFFF;
        border-radius: 30px;
        width: 150px;
        height: 42px;
        bottom: 27px;
        right: 38px;
    }

    .myOffers-modal-logoutText {
        color: black;
        font-weight: normal;
    }

    .myOffers-modal-param {
        display: block;
    }

    .myOffers-container-desktop-list {
        display: flex;
        flex-direction: column;
        min-height: 70vh;
    }
}

@media (min-width: 1000px) {

    .myOffers-content-list {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
    }
}

@media (min-width: 1100px) {
    .myOffers-main-col-1 {
        width: 75%;
    }

    .myOffers-main-col-2 {
        width: 25%;
    }

    .myOffers-nav {
        margin-left: 60px;
    }

    .myOffers-content-list-stats {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
    }
}

@media (min-width: 1200px) {
    .myOffers-content-list {
        grid-template-columns: repeat(4, minmax(0px, 1fr));
    }

}

@media (min-width: 1500px) {
    .myOffers-content-list-stats {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
    }
}

@media (min-width: 1600px) {
    .myOffers-content-list {
        grid-template-columns: repeat(5, minmax(0px, 1fr));
    }

}