.vote-header {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    padding-top: 80px;
    padding-left: 135px;
    padding-right: 135px;
}

.vote-header-title {
    font-size: 34px;
    padding-bottom: 10px;
    font-family: "Figtree";
    font-weight: bold;
}

.vote-header-subtitle {
    font-size: 16px;
    font-family: "Figtree";
}

.vote-rules {
    min-height: 100vh;
    background-color: #FEF7EA;
    padding-top: 50px;
    padding-left: 135px;
    padding-right: 135px;
}

.vote-rules h2 {
    font-size: 20px;
    margin-bottom: 25px;
    font-family: "Figtree"
}

.vote-rules ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
}

.vote-rules li {
    font-size: 16px;
    margin-bottom: 10px;
    font-family: "Figtree"
}

.vote-rules>ul:nth-child(2)>li:nth-child(5) {
    margin-bottom: 30px;
}

.vote-rules-glhf {
    font-size: 16px;
    font-family: "Figtree";
}

.vote-info-page {
    margin-top: 30px;
    padding: 25px 40px;
    border-radius: 20px;
    background-color: white;
    position: relative;
}

.vote-info-page p {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: "Figtree";
    line-height: 20px;
}

.voting-participation {
    height: 344px;
    background: #FFFFFF;
    border-radius: 20px;
}

.voting-participation p {
    color: #2A7AF5;
    font-weight: bold;
}

.vote-participation-title {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.vote-participation-warning {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #E85D4A;
}

.vote-info-reward-edit-button {
    position: absolute;
    right: 15px;
    bottom: 20px;
    border-radius: 8px;
    background-color: #2A7AF5;
    width: 125px;
    height: 45px;
    color: white;
    font-weight: bold;
    font-family: 'Figtree';
    font-size: 16px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
}

.vote-info-reward-edit-button-disable {
    position: absolute;
    right: 15px;
    bottom: 20px;
    border-radius: 8px;
    background-color: #6e6e6ea2;
    width: 125px;
    height: 45px;
    color: white;
    font-weight: bold;
    font-family: 'Figtree';
    font-size: 16px;
    border: none;
    text-transform: uppercase;
}

.vote-info-reward-card {
    width: 70%;
    height: 300px;
    margin-top: 30px;
    border: 2px dashed #F5761D;
    display: flex;
}

.vote-info-reward-rightcol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.vote-info-reward-div-content {
    width: calc(100% - 235px);
    margin-top: 10px;
}

.vote-info-reward-title {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
}

.vote-info-reward-text {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #676767;
}

.vote-info-reward-input-title {
    width: 100%;
    height: 40px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #ccc;
}

.vote-info-reward-input-text {
    width: 100%;
    height: 70px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #ccc;
    resize: none;
}

.vote-field-length {
    margin-left: 10px;
}

.vote-info-reward-plus-icon {
    width: 235px;
    height: 231px;
    margin-bottom: 40px;
    position: relative;
}

.vote-info-reward-img {
    width: 235px;
    height: 231px;
    border-radius: 7px;
    object-fit: cover;
}

.vote-info-icon-check {
    font-size: 25px;
    position: absolute;
    top: 25px;
    right: 30px;
}

.vote-stats {
    margin-top: 40px;
    padding: 25px 40px;
    border-radius: 20px;
    background-color: white;
}

.vote-stat-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.vote-stats-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 380px;
    height: 107px;
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    padding: 15px;
    position: relative;
}

.vote-stats-card-title {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

.vote-stats-card-number {
    position: absolute;
    bottom: 15px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
}

.vote-stats-card span {
    font-family: 'Figtree';
    font-style: normal;
    font-size: 16px;
    color: #2A7AF5;
}

.vote-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    padding: 12px 20px;
    background: #FFFFFF;
    border: 2px solid #2A7AF5;
    border-radius: 30px;
    cursor: pointer;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #2A7AF5;
}

.vote-btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    padding: 12px 20px;
    background: #2A7AF5;
    border: 2px solid #2A7AF5;
    border-radius: 30px;
    cursor: pointer;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: white;
}

.checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-family: "Figtree"
}

.vote-checkbox {
    margin-right: 10px;
}

.vote-previous-campaigns-wrap {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fill, 310px);
    gap: 40px;
    padding-bottom: 75px;
}

.vote-previous-campaigns {
    width: 310px;
    height: 310px;
    border: 1px solid #F5B12A;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.vote-previous-campaigns-content {
    position: absolute;
    width: 310px;
    height: 150px;
    left: 0px;
    bottom: 0px;
    background: #FFFFFF;
    padding: 15px;
}

.vote-previous-campaigns-content h3 {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #2A7AF5;
    padding-bottom: 18px;
}

.vote-previous-campaigns-row {
    display: flex;
    align-items: center;
    padding-bottom: 10px
}

.vote-previous-campaigns-row2 {
    display: flex;
    align-items: center;
}

.vote-previous-campaigns-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vote-previous-campaigns-icon {
    font-size: 25px;
    margin-right: 10px;
}

.vote-previous-campaigns-text-bold {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 6px;
}

.vote-previous-campaigns-text {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #676767;
}

.vote-previous-campaigns-span {
    padding: 2px 8px;
    background: #FCC707;
    border-radius: 5px;
    color: white;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    margin-right: 5px;
}

.reward-form-container {
    display: flex;
    justify-content: space-between;
}

.reward-form-left-col {
    width: 72%;
}

.reward-form-right-col {
    display: flex;
    flex-direction: column;
}

.reward-form h3,
.vote-info-page h3,
.vote-stats h3 {
    font-size: 20px;
    margin-bottom: 25px;
    font-family: "Figtree"
}

.form-group {
    margin-bottom: 50px;
}

.form-label {
    display: block;
    font-size: 16px;
    margin-bottom: 13px;
    font-family: "Figtree"
}

.form-input {
    width: 100%;
    height: 50px;
    padding: 10px;
    font-size: 16px;
    background: #F7F7F7;
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    font-family: "Figtree";
}

.form-textarea {
    width: 100%;
    height: 104px;
    padding: 10px;
    font-size: 16px;
    background: #F7F7F7;
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    font-family: "Figtree";
    resize: none;
}

.form-input:focus,
.form-textarea:focus {
    border: 1px solid #2A7AF5;
}

.form-picture {
    position: relative;
    display: flex;
    align-items: center;
    width: 235px;
    height: 235px;
    background: #F7F7F7;
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    cursor: pointer;
}

.form-picture-add {
    font-size: 45px;
    margin: 0 auto;
    cursor: pointer;
    color: #2A7AF5;
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    z-index: 99;
}

.submit-button {
    background: #2A7AF5;
    color: white;
    font-family: "Figtree";
    font-size: 16px;
    font-weight: bold;
    border-radius: 30px;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
}

.submit-button-disable {
    background-color: #CACACA;
    color: #fff;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 30px;
    font-family: "Figtree";
    font-weight: bold;
}

.submit-dots {
    width: 258px;
    height: 46px;
    background: #2A7AF5;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center
}

.vote-modal-box-participation-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 345px;
    height: 60px;
    background: rgba(51, 51, 51, 0.95);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.vote-modal-box-participation-message p {
    font-family: "Figtree";
    color: white;
    font-weight: bold;
}

.vote-modal-box-not-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 780px;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 30px 80px;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    box-shadow: 0px 5.48529px 10.9706px rgba(0, 0, 0, 0.25);
    border-radius: 10.9706px;
}

.vote-modal-not-selected-img {
    width: 210px;
    height: 210px;
    margin-bottom: 30px;
}

.vote-modal-not-selected-text {
    font-family: 'Figtree';
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    padding-bottom: 40px;
}

.vote-modal-not-selected-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}