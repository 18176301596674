.params-main {
    padding-top: 100px;
    padding-left: 135px;
    padding-right: 135px;
    padding-bottom: 100px;
    background: #FEF7EA;
}

.params-main-title {
    font-size: 34px;
    font-family: Figtree;
    font-weight: 700;
    padding-bottom: 20px;
}

.params-main-subtitle {
    font-size: 16px;
    font-family: Figtree;
    font-weight: 700;
    padding-bottom: 20px;
}

/* Form  */

.params-form-row {
    display: flex;
}

.params-form-card {
    display: flex;
    flex-direction: column;
    background: white;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
    border-radius: 20px;
}

.params-form-card2 {
    display: flex;
    background: white;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
    border-radius: 20px;
}

.params-form-card-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.params-form-label {
    font-size: 16px;
    font-family: Figtree;
    padding-bottom: 20px;
}

.params-form-row {
    display: flex;
    justify-content: space-between;

}

.params-form-img {
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 10px;
}

.params-form-profil-picture {
    width: 150px;
    height: 140px;
    border-radius: 5px;
}

.params-form-input {
    height: 50px;
    width: 50%;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 30px;
    border: 1px solid #AFAFAF;
    color: #ABACAC;
    font-family: 'Figtree';
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    text-overflow: "..."
}

.params-form-textarea {
    width: 50%;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 30px;
    border: 1px solid #AFAFAF;
    color: #ABACAC;
    font-family: 'Figtree';
    font-size: 16px;
    padding: 15px;
    text-overflow: "..."
}

.params-email-input {
    width: 50%;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 30px;
    border: 1px solid #AFAFAF;
    color: #ABACAC;
    font-family: 'Figtree';
    font-size: 16px;
    padding: 15px;
    text-overflow: "..."
}

.params-form-input:focus,
.params-form-textarea:focus,
.params-modify-information-textarea-placeholder,
.params-modify-input:focus,
.params-modify-information-input:focus,
.params-modify-information-textarea:focus,
.params-modify-information-textarea-placeholder:focus {
    border: 1px solid #2A7AF5;
}

.params-form-modify-btn {
    width: 171px;
    height: 40px;
    font-family: 'Figtree';
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    background: #2A7AF5;
    border-radius: 30px;
    border: none;
    margin-right: 10%;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.params-form-submit-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    right: 0;
    left: 0;
}

.params-form-submit {
    width: 171px;
    height: 52px;
    font-family: 'Figtree';
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    background: #2A7AF5;
    border-radius: 40px;
    border: none;
    cursor: pointer;
}

/* Modif Popup container */

.params-modify-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    left: 0px;
    right: 0px;
    z-index: 99;
    margin-top: -580px;
}

.params-modify-container2 {
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    left: 0px;
    right: 0px;
    z-index: 99;
    margin-top: -750px;
}

.params-modify-email-card {
    display: flex;
    flex-direction: column;
    width: 750px;
    height: 580px;
    background: #FFFFFF;
    box-shadow: 0px 5.48529px 10.9706px rgba(0, 0, 0, 0.25);
    border-radius: 10.9706px;
    padding: 40px;
}

.params-modify-informations-card {
    display: flex;
    flex-direction: column;
    width: 750px;
    height: 750px;
    background: #FFFFFF;
    box-shadow: 0px 5.48529px 10.9706px rgba(0, 0, 0, 0.25);
    border-radius: 10.9706px;
    padding: 20px 40px 0px 40px;
}

.params-modify-title {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 50px;
}

.params-modify-title2 {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 20px;
}

.params-modify-label {
    font-size: 16px;
    font-family: 'Figtree';
    padding-bottom: 10px;
}

.params-modify-input {
    height: 48px;
    border: 2.19412px solid #ABACAC;
    border-radius: 43.8824px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    background: transparent;
}


.params-modify-information-input {
    width: 90%;
    height: 48px;
    border: 1px solid #AFAFAF;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.params-modify-information-textarea {
    width: 90%;
    height: 100px;
    border: 1px solid #AFAFAF;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
    resize: none;
}

.params-modify-information-textarea-placeholder {
    width: 90%;
    height: 100px;
    border: 1px solid #AFAFAF;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
    color: gray;
    resize: none;
}

.params-modify-input-parent-confirm {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 48px;
    border: 2.19412px solid #ABACAC;
    border-radius: 43.8824px;
    align-items: center;
    overflow: hidden;
    margin-bottom: 15px;
}

.params-modify-input-parent-error {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 48px;
    border: 1px solid #FF0000;
    border-radius: 43.8824px;
    align-items: center;
    overflow: hidden;
    margin-bottom: 15px;
    background-color: #fff;
    position: relative;
}

.params-modify-input-confirm {
    width: 100%;
    border: none;
    border-radius: 43.8824px;
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.params-modify-confirm-error {
    color: #FF0000;
    font-size: 28px;
    margin-right: 12px;
}

.params-modify-input-confirm-error {
    width: 100%;
    height: 48px;
    border: 1px solid #FF0000;
    border-radius: 43.8824px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.params-input-errorMsg {
    position: absolute;
    right: 10px;
    color: red;
    text-align: center;
}

.params-modify-btnContainer {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
}

.params-modify-btnContainer2 {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.params-modify-cancel {
    width: 150px;
    height: 42px;
    border: 1px solid #2A7AF5;
    border-radius: 30px;
    font-family: 'Figtree';
    font-weight: 400;
    font-size: 16px;
    color: #2A7AF5;
    margin-right: 30px;
    background: transparent;
    cursor: pointer;
}

.params-modify-submit {
    width: 150px;
    height: 42px;
    border-radius: 30px;
    border: none;
    font-family: 'Figtree';
    font-weight: 400;
    font-size: 16px;
    color: white;
    margin-right: 30px;
    background: #CACACA;
}

.params-modify-submit-active {
    width: 150px;
    height: 42px;
    border: 1px solid #2A7AF5;
    border-radius: 30px;
    font-family: 'Figtree';
    font-weight: 400;
    font-size: 16px;
    background: #2A7AF5;
    color: white;
    margin-right: 30px;
    cursor: pointer;
}

.params-modify-submit-dots {
    width: 150px;
    height: 42px;
    border-width: 1px solid #2A7AF5;
    border-radius: 30;
    font-family: 'Figtree';
    font-weight: 400;
    font-size: 16;
    margin-right: 30;
    background: transparent;
}

.params-modify-submit-inactive {
    width: 150px;
    height: 42px;
    border: none;
    border-radius: 30px;
    font-family: 'Figtree';
    font-weight: 400;
    font-size: 16px;
    color: white;
    margin-right: 30px;
    background: #CACACA;
}

.params-modify-logo {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.params-modify-nologo {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #2A7AF5;
    background: white;
    cursor: pointer;
}

.params-modify-logo-icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #2A7AF5;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    bottom: -3px;
    left: 35px;
}

.params-modify-logo-icon {
    font-size: 14px;
    color: #2A7AF5;
}

.params-modify-profilPicture {
    width: 150px;
    height: 140px;
    border-radius: 5px;
}

.params-modify-noprofilPicture {
    width: 150px;
    height: 140px;
    border-radius: 5px;
    border: 1px solid #2A7AF5;
    background: white;
    cursor: pointer;
}

.params-modify-profilPicture-icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #2A7AF5;
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    bottom: -10px;
    left: 105px;
}

.params-modify-profilPicture-icon {
    font-size: 35px;
    color: #2A7AF5;
}