.delete-container {
    width: 100%;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    min-height: 100vh;
}

.delete-title {
    font-family: Figtree;
    font-size: 2.5vw;
    font-weight: bold;
}