/* HEADER */
.offerDetails-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background: white;
    z-index: 99;
}

.offerDetails-header-arrowback {
    font-size: 25px;
    cursor: pointer;
}

.offerDetails-header-profilcircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #AA80F9;
    border-radius: 20px;
    cursor: pointer;
}

.offerDetails-header-profilcircle-text {
    font-family: 'Figtree';
    font-weight: 700;
    font-size: 17px;
    color: white;
}


/* PC */
.offerDetails-header-desktop {
    display: none;
    align-items: center;
    justify-content: space-between;
    background: black;
    height: 45px;
    padding-left: 45px;
    padding-right: 45px;
    margin-top: 0;
    margin-bottom: 50px;
}

.offerDetails-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 60%;
    color: white;
}

.offerDetails-logo-title {
    font-family: "Geomatrix";
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 8px;
}

.offerDetails-logo-subTitle {
    border: 0.846154px solid white;
    border-radius: 3px;
    font-size: 15px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.offerDetails-container-profil {
    display: flex;
    justify-content: flex-end;
    width: 40%;
}

.offerDetails-profil {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #AA80F9;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 20px;
}

.offerDetails-profil-initial {
    color: white;
    font-weight: bold;
    font-size: 17px;
    font-family: "Figtree"
}

.offerDetails-profil-desktopHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.offerDetails-profil-username {
    font-family: 'Figtree';
    font-weight: 700;
    font-size: 16px;
    color: white;
    text-align: center;
}

.offerDetails-profil-arraydown {
    color: white;
    margin-left: 12px;
}

/* MAIN */
.offerDetails-main {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    margin: 0 auto;
}

.offerDetails-main-img {
    min-width: 100%;
    height: 0 auto;
    box-shadow: 0px 5.63725px 11.2745px rgba(0, 0, 0, 0.25);
    border-radius: 5.63725px;
    margin-bottom: 20px;
}

.offerDetails-main-name {
    font-family: 'Figtree';
    font-weight: 400;
    font-size: 22px;
    padding-bottom: 20px;
}

.offerDetails-main-date {
    font-family: 'Figtree';
    font-size: 18px;
    padding-bottom: 20px;
}

.offerDetails-main-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 77px;
    height: 28px;
    background: linear-gradient(97.25deg, #8270EE 1.21%, #3300FF 93.66%);
    border-radius: 3px;
}

.offerDetails-main-status-expired-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 77px;
    height: 28px;
    background: linear-gradient(97.25deg, #f1785a 1.21%, #ff0000 93.66%);
    border-radius: 3px;
}

.offerDetails-main-status-text {
    font-family: 'Figtree';
    font-style: normal;
    font-size: 14px;
    color: white;
}

.offerDetails-main-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 180px;
    margin-top: 55px;
    margin-bottom: 100px;
    background: #e5e3f0;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
}

.offerDetails-main-card-text {
    font-size: 18px;
    font-family: "Figtree";

}

.offerDetails-main-card-span {
    font-size: 18px;
    font-weight: bold;
    font-family: "Figtree";
    color: rgb(51, 0, 255)
}

/* Panel Left */
.offerDetails-left-panel {
    display: none;
    flex-direction: column;
    /* position: absolute; */
    position: sticky;
    top: 45px;
    left: 0;
    width: 250px;
    background-color: #202020;
    height: 240px;
}

.offerDetails-panel-previewFocus {
    display: flex;
    align-items: center;
    padding-left: 45px;
    background: #AFAFAF;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.offerDetails-panel-preview {
    display: flex;
    align-items: center;
    padding-left: 45px;
    background: #3B393D;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.offerDetails-panel-actionFocus {
    display: flex;
    padding-left: 40px;
    padding-right:8px;
    align-items: center;
    background: #AFAFAF;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.offerDetails-panel-action {
    display: flex;
    padding-left: 40px;
    padding-right:8px;
    align-items: center;
    background: #202020;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.offerDetails-panel-navTitle {
    font-size: 18px;
    color: white;
    font-family: Figtree;
    line-height: 21px;
}

.offerDetails-right-panel-content {
    display: none;
    padding-left: 250px;
    background: #202020;
}

/* DESKTOP */
@media (min-width: 768px) {
    .offerDetails-header {
        display: none;
    }

    .offerDetails-header-desktop {
        display: flex;
        margin-bottom: 0;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 99;
    }

    .offerDetails-main {
        display: none;
    }

    .offerDetails-left-panel {
        display: flex;
    }

    .offerDetails-right-panel-content {
        display: flex;
        margin-top: -240px;
    }

}